// @ts-nocheck

import moment from "moment";

export default {
  methods: {
    getDefaultFilter(filterType) {
      return [
        {
          name: "yesterday handover",
          description: "yesterday handover chat",
          styles: {
            color: "white",
            backgroundColor: "dimgrey",
            extras: {},
          },
          type: filterType,
          conditions: [
            { key: "searchTerm", value: "" },
            { key: "chatSources", value: "" },
            { key: "contextHistory", value: "handover" },
          ],
          dateRange: {
            type: "text",
            value: "last X days", // X <= 7
          },
        },
      ];
    },
  },
  computed: {
    coreFormMixin() {
      return {
        rtlMode: {
          default: false,
          type: "boolean",
          label: "Arabic mode",
          detail: "If enabled, change webchat widget to Arabic mode",
        },
        contextExpiryTiming: {
          label: "Time until context expires (in minutes)",
          detail: "Set the timing for chat message to expire",
          default: 1.5,
          type: "number",
        },
        session_length: {
          default: 30,
          min: 0.5,
          type: "number",
          label: "Session Length (in minutes)",
          detail: "The number of minutes before the bot starts to refresh for new session",
        },
        fileStorageAntivirus: {
          default: true,
          type: "boolean",
          label: "Enable Antivirus for file storage upload",
          detail: "If enabled, will run antivirus scanning first before upload",
        },
        officeHour: {
          default: {
            enabled: false,
            datetimes: [
              moment("2013-02-08 08:00:00").toDate(),
              moment("2013-02-08 19:00:00").toDate(),
            ],
          },
          type: "object",
          label: "Operating / Office Hours",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            datetimes: {
              default: [
                moment("2013-02-08 08:00:00").toDate(),
                moment("2013-02-08 19:00:00").toDate(),
              ],
              type: "OfficeHourDatetimes",
              label: "Time",
              detail: "Indicated operating hours.",
            },
            departmentDatetimes: {
              default: {},
              type: "OfficeHourDepartmentDatetimes",
              departments: this.departments,
            },
          },
        },
        limitMaximumConcurrentUsers: {
          default: {
            enabled: false,
            max: 200,
          },
          type: "object",
          label: "Limit maximum number of concurrent users on the webchat",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            max: {
              default: 200,
              min: 0,
              type: "number",
              label: "Maximum number",
              detail:
                "The maximum number of concurrent webchat users allowed at any one point before the webchat launcher is not shown to users",
            },
          },
        },
        channelCephStorageProvider: {
          default: {
            bucketName: "channel",
          },
          type: "object",
          label: "Ceph Storage for All Channels Agents/Users File Upload",
          detail: "Storage info to be separate from dashboard Files storage",
          children: {
            bucketName: {
              default: "channel",
              type: "string",
              label: "Folder Name",
              detail: "Folder name used by ceph storage",
            },
          },
        },
        inactivityManager: {
          default: {
            bot: {
              enabled: false,
              threshold: 200,
              contentNode: "",
              // 2nd prompt
              secondPrompt: false,
              secondPromptThreshold: 200,
              secondPromptContentNode: "",
            },
            livechat: {
              enabled: false,
              threshold: 200,
              contentNode: "",
              autoResolve: false,
              autoResolveWait: 0,
              // 2nd prompt
              enabledSecondPrompt: false,
              thresholdSecondPrompt: 200,
              contentNodeSecondPrompt: "",
              autoResolveSecondPrompt: false,
              autoResolveWaitSecondPrompt: 0,
              forceAutoResolve: false,
              forceAutoResolveWait: 0,
              skipHavingBothInteraction: false,
            },
            livechatAgent: {
              enabled: false,
              threshold: 200,
              contentNode: "",
            },
          },
          type: "InactivityManager",
        },
        liveChatFilters: {
          default: {
            enabled: false,
            filters: {
              monitor: {},
              queued: {},
              resolved: {},
            },
          },
          type: "object",
          label: "Live chat custom filtering",
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "Enable",
            },
            queuedChat: {
              default: this.getDefaultFilter("queuedChat"),
              type: "json",
              label: "For queuedChat view",
              detail: "Filtering queuedChat chats",
            },
            resolvedChat: {
              default: this.getDefaultFilter("resolvedChat"),
              type: "json",
              label: "For resolvedChat view",
              detail: "Filtering resolvedChat chats",
            },
            monitorChat: {
              default: this.getDefaultFilter("monitorChat"),
              type: "json",
              label: "For monitor view",
              detail: "Filtering monitor chats",
            },
          },
        },
        singlishMapping: {
          default: {
            additionalNouns: [],
            mappings: [],
          },
          type: "object",
          label: "Mapping for Singlish/English Abbreviation",
          children: {
            additionalNouns: {
              label: "Additional Nouns",
              detail:
                "By default, the system has an abbreviation detector that will automatically replace the (b, n, u, c, r, v, y) to (be, and, you, see, are, very, why) and skipping the 'Nouns' to be replace. But, the detector has a laxity of available 'Nouns', can put any word/noun here that NEED TO BE SKIP the replacement.",
              default: [],
              type: "list",
            },
            mappings: {
              label: "Mappings",
              default: [
                {
                  regex: "\\b(d|z|tge)\\b",
                  replace: "the",
                },
                {
                  regex: "\\b('m)\\b",
                  replace: " am",
                },
                {
                  regex: "\\b(tt|tat|tht|liddat|lyk dat)\\b",
                  replace: "that",
                },
                {
                  regex:
                    "\\b(thnx|tk|tks|thx|thks|tq|tqvm|ty|thankyou|kamxia|gamsia|kamxiahamida)\\b",
                  replace: "thanks",
                },
                {
                  regex: "\\b(pls|plz|pleas|pease|pleaz|plez|ps|plse|pl)\\b",
                  replace: "please",
                },
                {
                  regex: "\\b(ans|answr)\\b,",
                  replace: "answer",
                },
                {
                  regex: "\\b(nv|neva|nvr|nver|nevr|neh)\\b",
                  replace: "never",
                },
                {
                  regex: "\\b(ard|arnd)\\b",
                  replace: "around",
                },
                {
                  regex: "\\b(msg|msge|text|txt)\\b",
                  replace: "message",
                },
                {
                  regex: "\\b(bcoz|cos|cause|cuz|cus|bcus|bcuz|becaus|bcause|bcos|bcz|bcs)\\b",
                  replace: "because",
                },
                {
                  regex: "\\b(respond|rpy|rply|rpl)\\b",
                  replace: "reply",
                },
                {
                  regex: "\\b(ppl|pp|peoples|peeps|peep)\\b",
                  replace: "people",
                },
                {
                  regex: "\\b(ok|okays|okz|okie|okies|okiez|fine|sure|alright|okey|okeyz|okeys)\\b",
                  replace: "okay",
                },
                {
                  regex: "\\b(2day|tday|tdy|2dy)\\b",
                  replace: "today",
                },
                {
                  regex: "\\b(bef|b4|bfre|bfore)\\b",
                  replace: "before",
                },
                {
                  regex: "\\b(tym|tim|timee|tme|masa)\\b",
                  replace: "time",
                },
                {
                  regex: "\\b(mobile|cell|fone|hp|phne|telefon)\\b",
                  replace: "phone",
                },
                {
                  regex: "\\b(sowwy|soz|sorx|sorr|sry|srry|maaf|paiseh)\\b",
                  replace: "sorry",
                },
                {
                  regex: "\\b(hm|hom|hme|house|hse|rumah|kampong|balek|campong|kampung)\\b",
                  replace: "home",
                },
                {
                  regex: "\\b(wat|wht|waht|wad|wd|apa|simi)\\b",
                  replace: "what",
                },
                {
                  regex: "\\b(lyk|lik|likee|likes|suka)\\b",
                  replace: "like",
                },
                {
                  regex: "\\b(cn|boleh)\\b",
                  replace: "can",
                },
                {
                  regex: "\\b(buay tahan|cannot take it)\\b",
                  replace: "cannot",
                },
                {
                  regex: "\\b(ur|yr)\\b",
                  replace: "your",
                },
                {
                  regex: "\\b(goin|gonna|gng)\\b",
                  replace: "going",
                },
                {
                  regex: "\\b(havent|hvn|hvnt|havenot|hvent| haven’t)\\b",
                  replace: "have not",
                },
                {
                  regex: "\\b(cud|cld|culd)\\b",
                  replace: "could",
                },
                {
                  regex: "\\b(don't|dun|dnt)\\b",
                  replace: "do not",
                },
                {
                  regex: "\\b(chng|changee|chnge|cng)\\b",
                  replace: "change",
                },
                {
                  regex: "\\b(advice|adv|adbice)\\b",
                  replace: "advise",
                },
                {
                  regex: "\\b(whr|whre|wher|di mana|where got)\\b",
                  replace: "where",
                },
                {
                  regex: "\\b(bt)\\b",
                  replace: "but",
                },
                {
                  regex: "\\b(yah|yeah|ya|yup|yea|yas|yep|yap|yaas)\\b",
                  replace: "yes",
                },
                {
                  regex: "\\b(btw)\\b",
                  replace: "by the way",
                },
                {
                  regex: "\\b(nw)\\b",
                  replace: "now",
                },
                {
                  regex: "\\b(knows|konw|kown|nkow|nkwo|knwo|tahu)\\b",
                  replace: "know",
                },
                {
                  regex: "\\b(chk|checking|chck|chking)\\b",
                  replace: "check",
                },
                {
                  regex: "\\b(wah|aiya|aiyah|aiyo|aiyoh|alamak|choy|choi|eee|hah|hor)\\b",
                  replace: "wow",
                },
                {
                  regex: "\\b(ah ?beng|ah ?lian|pai ?kia|pai ?nang|pai ?lang|samseng)\\b",
                  replace: "gangster",
                },
                {
                  regex: "\\b(ahkwa|ahkua|302|ah ?kwa|a-?jay|gu ?niang|ku ?niang)\\b",
                  replace: "gay",
                },
                {
                  regex: "\\b(ah ?long)\\b",
                  replace: "loan shark",
                },
                {
                  regex: "\\b(ah ?pek|ahcek)\\b",
                  replace: "grandfather",
                },
                {
                  regex: "\\b(ah soh)\\b",
                  replace: "auntie",
                },
                {
                  regex: "\\b(nvm|chey|cheh)\\b",
                  replace: "nevermind",
                },
                {
                  regex: "\\b(angmoh|ang moh|jiak kantang|chia kentang)\\b",
                  replace: "Caucasian",
                },
                {
                  regex: "\\b(atas|hao ?lian|yaya|yah yah|yahyah|ya ya)\\b",
                  replace: "arrogant",
                },

                {
                  regex: "/\b(barang|ting|tings|stuff)\\b",
                  replace: "things",
                },
                {
                  regex: "\\b(belanja|blanja|blanjah)\\b",
                  replace: "sponsor",
                },
                {
                  regex:
                    "\\b(blur|sotong|catch no ball|liak bo kiu|cheem|gabra|kalang kabok|kalang kabut|kelam kabut|mabok|mabuk)\\b",
                  replace: "confused",
                },
                {
                  regex:
                    "\\b(Bochap|idc|bohew|bohiu|go fly kite|fuck care|heck care|fuck spider|like nobody’s business)\\b",
                  replace: "don’t care",
                },
                {
                  regex: "\\b(bobian|bo bian|gone case|habis)\\b",
                  replace: "no choice",
                },
                {
                  regex: "\\b(bodoh|stupiak|goondu|goondoo|gorblock|kotek|toot|bobo)\\b",
                  replace: "stupid",
                },
                {
                  regex: "\\b(botak)\\b",
                  replace: "bald",
                },
                {
                  regex: "\\b(buaysong|buay song)\\b",
                  replace: "unhappy",
                },
                {
                  regex: "\\b(extra|calafare|calefare|callafair|calafair|cairlairfare|kalafare)\\b",
                  replace: "supporter",
                },
                {
                  regex:
                    "\\b(ch?ao ?keng|ch?ao ?geng|jiakzua|jia zhua|chia chua|jiak chua|idle king|lepak)\\b",
                  replace: "laze",
                },
                {
                  regex: "\\b(jialat|chia lat|horrigible)\\b",
                  replace: "bad",
                },
                {
                  regex: "\\b(anyhow|chinchai|chin cai|chin chai|rojak)\\b",
                  replace: "careless",
                },
                {
                  regex: "\\b(chio)\\b",
                  replace: "attractive",
                },
                {
                  regex: "\\b(chope)\\b",
                  replace: "reserve",
                },
                {
                  regex: "\\b(gahmen)\\b",
                  replace: "government",
                },
                {
                  regex: "\\b(geng|keng|siam|chao keng|keng king|kengster)\\b",
                  replace: "avoid",
                },
                {
                  regex:
                    "\\b(siao|gila|seow|seow char bor|siow char bor|seow da bor|siow da bor)\\b",
                  replace: "crazy",
                },
                {
                  regex: "\\b(gostan)\\b",
                  replace: "reverse",
                },
                {
                  regex:
                    "\\b(kio tio|kheotio|keoh teoh|kiohteo|keotio|kheodio|kio dio|keoh tio|keohtio|keo tio|keo dio)\\b",
                  replace: "lucky",
                },
                {
                  regex: "\\b(hiau|hiow|heow)\\b",
                  replace: "vain",
                },
                {
                  regex: "\\b(hosay|hoseh|kilat|shiok)\\b",
                  replace: "good",
                },
                {
                  regex: "\\b(((k|g)an ?(che|chi|ji)ong)( spider)?)\\b",
                  replace: "hasty",
                },
                {
                  regex: "\\b(kangtao|lobang|kang tao)\\b",
                  replace: "deals",
                },
                {
                  regex: "\\b(kehkiang|gehkiang|kaykiang|keh kiang|geh kiang|kay kiang|wayang)\\b",
                  replace: "act",
                },
                {
                  regex: "\\b(kaypoh|gaypoh|kpo|gehpo|gepoh|kehpo|kehpoh|kay poh)\\b",
                  replace: "busybody",
                },
                {
                  regex: "\\b(kiasu)\\b",
                  replace: "afraid of losing",
                },
                {
                  regex: "\\b(kiasi)\\b",
                  replace: "afraid of dying",
                },
                {
                  regex: "\\b(lagi)\\b",
                  replace: "more",
                },
                {
                  regex: "\\b(lah|leh|lor|mah|liao|huh|sia)\\b",
                  replace: "",
                },
                {
                  regex: "\\b(lecheh|leceh|shag|siong|xiong|susah)\\b",
                  replace: "troublesome",
                },
                {
                  regex: "\\b(liam keng|liamkeng|liam geng|liam geng)\\b",
                  replace: "nag",
                },
                {
                  regex: "\\b(longkang|long kang|longgang|long gang)\\b",
                  replace: "drain",
                },
                {
                  regex:
                    "\\b(lau kwee|malu|laukwee|lao kwee|laokwee|laukui|laokui|lao kui|lau kui)\\b",
                  replace: "embarrassed",
                },
                {
                  regex: "\\b(mati|si liao)\\b",
                  replace: "die",
                },
                {
                  regex: "\\b(obiang|orbiang)\\b",
                  replace: "weird",
                },
                {
                  regex: "\\b(pau kar liao|bao gar liao|pao kar liao|saikang warrior|sai kang)\\b",
                  replace: "minion",
                },
                {
                  regex: "\\b(sabo)\\b",
                  replace: "sabotage",
                },
                {
                  regex: "\\b(salah?)\\b",
                  replace: "wrong",
                },
                {
                  regex: "\\b(bo ?liao)\\b",
                  replace: "bored",
                },
                {
                  regex: "\\b(sayang)\\b",
                  replace: "pat",
                },
                {
                  regex: "\\b(s(i|ee) ?(b|p)e(i|h))\\b",
                  replace: "very",
                },
                {
                  regex: "\\b(sinseh|sensei)\\b",
                  replace: "doctor",
                },
                {
                  regex: "\\b(suay)\\b",
                  replace: "unlucky",
                },
                {
                  regex: "\\b(wah? ?la(o|u|n)( eh)?|wah? ?piang|wake up your idea|your head)\\b",
                  replace: "annoyed",
                },
                {
                  regex: "\\b(steady|zai)\\b",
                  replace: "capable",
                },
              ],
              type: "json",
            },
          },
        },
        choiceQuickMatch: {
          default: {},
          type: "object",
          label: "Choices Quick Match",
          detail: 'Patterns set for "choices" feature in text handler',
          children: {
            enabled: {
              default: false,
              type: "boolean",
              detail: "To enable quick match function in determining matched choices",
            },
            patterns: {
              default: ["/^(the )?first( one)?.?$/i", "/^(the )?second( one)?.?$/i"],
              type: "json",
              label: "List of patterns",
              detail: "Array of regular expression in string format",
            },
          },
        },
      };
    },
  },
};
